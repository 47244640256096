<template>
  <div>
    <div class="content-1to7">
      <app-form-rate
        v-model="inputValue"
        :items="returnMyItems"
        v-on:change.native="response(inputValue)"
        item-value="value"
        :isGradient="question.gradient"
        :textColor="colors.actionText"
        :labelColor="colors.splashColor"
        :bgColor="colors.actionColor"
        :hoverColor="colors.buttonHover"
        item-text="label"
        :min-label="question.startLabel"
        :max-label="question.endLabel"
      />
    </div>
  </div>
</template>

<script>
import { gradientMaker } from '@/_helpers'
export default {
  name: 'app10num',
  props: ['colors', 'question'],
  components: {
    'app-form-rate': () => import('@binds-tech/binds-design-system/src/components/Form/Rate')
  },
  data () {
    return {
      inputValue: null,
      nes: [
        { label: '1', value: 0 },
        { label: '2', value: 15 },
        { label: '3', value: 30 },
        { label: '4', value: 50 },
        { label: '5', value: 65 },
        { label: '6', value: 80 },
        { label: '7', value: 100 }
      ]
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.nextQuestion({ skip: true })
      }
    })
  },
  computed: {
    returnMyItems () {
      const item = [...this.nes]
      gradientMaker.checkGradientAndReturnTheColors(this.question, item)
      return item
    }
  },
  methods: {
    response (value) {
      const body = {}
      body.rating = value
      this.nextQuestion(body)
    },
    nextQuestion (body) {
      this.$emit('value-changed', body)
      this.$emit('question-answered', true)
    }
  }
}
</script>
<style lang="scss">
.content-1to7 {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  .rating {
    width: 400px;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    .rating__item {
      div {
        .rating__button {
          font-size: 20px;
        }
      }
    }
  }
}

//responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-1to7 {
    height: 100%;
    width: 90%;
  }
}
</style>
